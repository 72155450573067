@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap";
* {
  box-sizing: border-box;
}

body {
  background-color: #142a4c;
  margin: 0;
  font-family: Montserrat, sans-serif;
}
/*# sourceMappingURL=index.78cb3893.css.map */
